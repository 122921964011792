@import "../theme/variables.css";

:root {
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 22px;
}

ion-content ion-toolbar {
  --background: transparent;
}

.track-c {
  color: var(--ion-color-warning);  
}

.track-t .track-v .track-m {
  font-style: italic;
}

.tanda-item-t {
  --background-color: green;
  --border-width: thick;
  --border-color: var(--ion-color-primary);
  --border-radius: 10pt;
}

.tanda-item-v {
  --background-color: lightblue;
  --border-width: thick;
  --border-radius: 10pt;
  --border-color: var(--ion-color-secondary);
}

.tanda-item-m {
  --border-width: thick;
  --border-radius: 8pt;
  --border-color: var(--ion-color-tertiary);
  --width: inherit;
}

.tanda{
  --border-width: thick;
  --border-radius: 8pt;
}

.set{
  border-width: 5pt;
  border-radius: 10pt;
  --border-color: black;
  --width: _inherit;
  width: _inherit;
  min-width: _inherit;
  border-width: 5pt;
  --border-radius: 10pt;
  border-color: black;
  }


.milonga{
    /* --border-width: 2pt;
    --border-radius: 4pt;*/
    --border-color: black; 
    --border-top: medium;
    /* border-top: medium; */
    border-bottom: medium;
    }

.milonga_paid{
      /* --border-width: 2pt;
      --border-radius: 4pt;*/
      --border-color: black; 
      --border-top: medium;
      /* border-top: medium; */
      border-bottom: medium;
      color:var(--ion-color-success);
      }
  
.my-custom-modal-css .modal-wrapper {
    --top: 80%;
    --vertical-align: top;
    --width:30%;
    border-radius:10px;
    border-color: grey;
    background-color: grey;
    

  }

  .button-row {
    align-self: center;
  }

  .form-header {
    --align-content: center;
    text-align: center;
    align-items: center;
  }
  
  .section-header{
    background-color: palegreen;
    text-justify: initial;
    text-align: center;
    width: 100vw;
  }

.form-section {
  background-color: grey;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}
.player {

  align-items: center;
  vertical-align: middle;
}

.track-highlight {
  color: green;
  font-weight: bolder;
}

.alert {
  color: black;
  font-weight: bold;
}

.clickable {
  color: blue;
  text-decoration: underline blue;
  cursor: pointer;

}

.center{
  /* padding-left: '50%'; */
  text-align: center;
}

.clickable-track {
  cursor: pointer;
}

.clickable-track-c {
  color: var(--ion-color-warning);
  text-decoration: _underline;
  cursor: pointer;

}

.clickable-item {
  cursor: pointer;
}

.button_outline {
  color: green;

}

.large-icon{
  font-size: 50px;
  margin-right: 100%;
}

.side-icon{
  vertical-align: middle;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.play-icons {
  width: 80%;
}

.play-align{
  text-align: right;  
}

.ion-page {
  align-items: flex-start;
  max-width: 100%;
  margin: 0;
}

.page-row{
  height: 95%;
}
.col-style{
  background:  var(--ion-color-primary);

}

.pl-grid{
  width: 50%;
}

* {margin: 0; padding: 0;}
/* #container {height: 100%; width:100%; font-size:0;} */
#left, #middle, #right {display: inline-block; *display: inline; zoom: 1; vertical-align: top; font-size: 12px;}
#left {width: 50%; background: _blue;}
#middle {width: 50%; background: _green;}
#right {width: 25%; background: yellow; text-align: right;}

.box{
  position: relative;
  display: inline-block; /* Make the width of box same as image */
}
.box .text{
  position: absolute;
  z-index: _999;
  margin: 0 auto;
  left: 0;
  right: 0;
  _top: 40%; /* Adjust this value to move the positioned div up and down */
  text-align: _center;
  _width: 60%; /* Set the width of the positioned div */
}

.img-float{
  float: right;
}

.box-float{
  float: left;
  padding: 5px;
}

.clearfix {
  overflow: auto;
}

.title-center {
  margin-left: '50%';
}

.blocktext {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%) 
}


.menu-button {
  background-color: transparent;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-40%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.header {
  padding-right: 10%;
}

.preview-link{
  color: var(--ion-color-success);  
}